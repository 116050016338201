<template>
  <div class="loading">
     <v-img src="@/assets/loading.gif" alt="Loading"></v-img> 

  </div>    
</template>

<script>
export default {
    name: 'Loading'

}
</script>

<style>
      .loading {
        grid-area: content;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>