<template>
  <!-- clipped-left
    clipped-right -->
  <v-app-bar app clipped-left clipped-right outlined color="transparent">
    <v-app-bar-nav-icon
      @click="setSidebarDrawer(!Sidebar_drawer)"
      v-if="user"
    />
    <!-- <v-app-bar-nav-icon
      class="d-block d-md-none"
      @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
    /> -->
    <v-spacer></v-spacer>
    <v-toolbar-title class="align-center d-flex">
      <!-- <span class="display-2 mb-6': $breakpoint.mdAndUp, 'display-1 mb-2': $breakpoint.smAndDown"> {{ title }} </span> -->
      <v-img
        v-if="!$vuetify.breakpoint.smAndDown"
        max-height="100"
        max-width="150"
        :src="require('@/assets/logidoc.png')"
      />
    </v-toolbar-title>
    <v-toolbar-title class="pl-2 ma-0">
      {{ user.razao_social }}
      <v-card-subtitle class="pa-0">
        {{ cnpjFormatado }}
      </v-card-subtitle>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- <div class="d-flex flex-row float-right" v-if="user">
      <v-list-item class="pa-0 ma-0">
        <v-list-item-avatar class="pa-0 ma-0 right">
          <img src="https://randomuser.me/api/portraits/men/81.jpg"/>
          <Gravatar email="user.email" alt="User" />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="pa-0">
        <v-list-item-content class="d-none d-lg-inline right">
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption"
            >Webdesigner</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </div> -->
    <v-menu v-if="user" bottom left transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userProfileList"
          :key="i"
          @click="href(item)"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <ListVersion :mostraSobre="showDialog" /> -->

    <!-- <v-snackbar top="true" v-model="snackbar">
      {{ textoSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
                <v-btn color="" text v-bind="attrs" @click="showDialog = true">
          Fechar
        </v-btn>
        <ListVersion :mostraDialog="showDialog"></ListVersion>

      </template>
    </v-snackbar> -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
// import ListVersion from "@/components/LogVersao.vue";

import { userKey } from "@/global";

import { version } from "@/../package.json";

export default {
  name: "Header",
  // components: {
  //   ListVersion: () => import("@/components/LogVersao.vue"),
  // },

  props: {
    title: String,
    hideToggle: Boolean,
    hideUserDropDown: Boolean,

    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    appVersion: version,
    showDialog: false,
    snackbar: false,
    textoSnackbar: "",

    href(item) {
      if (item.title == "Sair") {
        return this.logout();
        // } else if (item.title == "Sobre") {
        //   return this.sobre();
      } else return undefined;
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    ...mapState(["user"]),
    cnpjFormatado() {
      if (this.user.cnpj) {
        let cnpjFormatado = this.user.cnpj.padStart(14, "0");
        cnpjFormatado = cnpjFormatado.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
        return cnpjFormatado;
      } else {
        return "Administracao";
      }
    },
    userProfileList() {
      let userProfileFilter = [];
      if (this.user.admin) {
        userProfileFilter = [
          {
            title: "Administração",
            icon: "mdi-account-supervisor",
            to: "/admin",
            admin: true,
          },
          {
            title: "Acesso API",
            icon: "mdi-api",
            to: "/adminAccess",
            admin: true,
          },
          {
            title: "Empresas SSH",
            icon: "mdi-ssh",
            to: "/adminEmpresasSSH",
            admin: true,
          },
          {
            title: "Dispositivos SSH",
            icon: "mdi-electric-switch",
            to: "/adminDispositivosSSH",
            admin: true,
          },
          {
            title: "Lista portas utilizadas SSH",
            icon: "mdi-list-box",
            to: "/adminListaPortasSSH",
            admin: true,
          },

          {
            title: "Sobre",
            icon: "mdi-information-outline",
            to: "/sobre",
            admin: false,
          },
          { title: "Sair", icon: "mdi-exit-to-app", to: "", admin: false },
        ];
      } else if (this.user.employee) {
        userProfileFilter = [
          {
            title: "Lista portas utilizadas SSH",
            icon: "mdi-list-box",
            to: "/adminListaPortasSSH",
            employee: true,
          },

          {
            title: "Sobre",
            icon: "mdi-information-outline",
            to: "/sobre",
            admin: false,
          },
          { title: "Sair", icon: "mdi-exit-to-app", to: "", admin: false },
        ];
      } else {
        userProfileFilter = [
          {
            title: "Sobre",
            icon: "mdi-information-outline",
            to: "/sobre",
            admin: false,
          },
          { title: "Sair", icon: "mdi-exit-to-app", to: "", admin: false },
        ];
      }
      return userProfileFilter;
    },
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    logout() {
      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.$router.push({ name: "auth" });
    },
    // sobre() {
    //   this.showDialog = true;

    //   // this.textoSnackbar = 'Versão do app : '+this.appVersion
    // },
  },
};
</script>

<style>
.title {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}

.title a {
  color: #fff;
  text-decoration: none;
}

.title a:hover {
  color: #fff;
  text-decoration: none;
}
</style>
