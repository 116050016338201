import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "vuetify/dist/vuetify.min.css";
import './msgs'
import './axios'
import router from './router'
import store from './store'
import './plugins/base'
import VueApexCharts from 'vue-apexcharts';
import VTooltip from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'








Vue.config.productionTip = false

//temporario 
//require('axios').defaults.headers.common['Authorization'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwibmFtZSI6Ikd1aSB0ZXN0ZSBlZGl0IiwiZW1haWwiOiJndWlsaGVybUB0ZXN0ZS5jb20uYnIiLCJhZG1pbiI6dHJ1ZSwiaWF0IjoxNjMxMTg4Nzc2LCJleHAiOjE2MzE0NDc5NzZ9.tImbTu1H8op9VcFTB2UZR2PHq6fSzUKHFHolae87CRU'

// const iconsGroup = localStorage.getItem("current_icons_group") || "fa";

Vue.component('apexchart', VueApexCharts)

Vue.use(VTooltip);



new Vue( {
    vuetify,
    store,
    VTooltip,
    router,
    render: h => h(App)
}).$mount('#app')
