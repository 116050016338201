<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <Layout />
  </v-app>

</template>

<script>



import Layout from "@/layouts/Layout"

export default {
  name: 'App',

  components: { Layout }
  

 
};
</script>
