import Vue from "vue";

export const userKey = "__knowledge_user";
export const userDashboard = "__dashboard_user";
export const equipamentoDashboard = "__dashboard_equipamento";
//export const baseApiUrl = "https://express.mine.nu"
export const baseApiUrl = "https://backend.mine.nu";
//export const baseApiUrl = "http://logicbox2.mine.nu:3000"
//export const baseApiUrl = "http://10.1.4.29:3000";

export function showError(e) {
  if (e && e.response && e.response.data) {
    if (typeof e.response.data === "string") {
      Vue.$toast(e.response.data, { type: "error" });
    } else if (typeof e.response.data.detail === "string") {
      Vue.$toast(
        "Erro status:" +
          e.response.status +
          " Detalhes : " +
          e.response.data.detail,
        { type: "error" }
      );
    } else {
      console.log(" 2 :" + e);
      Vue.$toast("Erro status:" + e.response.status, { type: "error" });
    }
  } else if (typeof e === "string") {
    // Vue.$toast( e , { type: 'error' } )
    Vue.$toast(e, { type: "error" });
  } else {
    Vue.$toast("Erro no processo !", { type: "error" });
  }
}

export function showSucess(msg) {
  if (!msg || msg.length == 0) {
    Vue.$toast("Operação realidada com sucesso!", { type: "success" });
  } else {
    Vue.$toast(msg, { type: "success" });
  }
}

export function validarCNPJ(cnpj) {
  // Remove os caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]+/g, "");

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs inválidos conhecidos
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Valida os dígitos verificadores
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export default {
  baseApiUrl,
  showError,
  showSucess,
  userKey,
  userDashboard,
  equipamentoDashboard,
  validarCNPJ,
};

// export default { baseApiUrl}
