import Vue from 'vue'


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"






Vue.use(Toast, {
    draggable: true,
    position: "bottom-right",

    // icon: {
    //     iconClass: 'material-icons',  // Optional
    //   }

})




// Vue.toasted.register(
//     'defaultSuccess',
//     payload => !payload.msg ? 'Operação realidada com sucesso!' : payload.msg,
//     { type: 'success', icon: 'check' }
// )

// Vue.toasted.register(
//     'defaultError',
//     payload => !payload.msg ? 'Oops.. Erro inesperado.' : payload.msg,
//     { type : 'error', icon : 'times' }
// )