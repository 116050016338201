import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { userKey } from "@/global";
import { mapState } from "vuex";

Vue.use(Router);

const router = new Router({
  computed: {
    ...mapState(["rotaAtual"]),
  },

  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      name: "home",
      path: "/",
      //  component: () => import('@/views/pages/Teste'),
      component: () => import("@/views/pages/HomeConsolidado"),
    },
    {
      name: "adminPages",
      path: "/admin",
      component: () => import("@/views/admin/AdminPages"),
      meta: { requiresAdmin: true },
    },
    {
      name: "adminAccess",
      path: "/adminAccess",
      component: () => import("@/views/admin/AdminAccess"),
      meta: { requiresAdmin: true },
    },
    {
      name: "adminEmpresasSSH",
      path: "/adminEmpresasSSH",
      component: () => import("@/views/admin/AdminEmpresasSSH"),
      meta: { requiresAdmin: true },
    },
    {
      name: "adminDispositivosSSH",
      path: "/adminDispositivosSSH",
      component: () => import("@/views/admin/AdminDispositivosSSH"),
      meta: { requiresAdmin: true },
    },
    {
      name: "adminListaPortasSSH",
      path: "/adminListaPortasSSH",
      component: () => import("@/views/admin/AdminListaPortasSSH"),
      meta: { requiresAdmin: true },
      //meta: { requiresEmployee: true },
    },

    {
      name: "adminAccessLog",
      path: "/adminAccessLog",
      component: () => import("@/views/admin/AdminAccessLog"),
      meta: { requiresAdmin: true },
    },
    {
      name: "sobre",
      path: "/sobre",
      component: () => import("@/views/pages/Sobre"),
    },

    // {
    //     name: 'teste',
    //     path: '/teste',
    //     component: () => import('@/views/pages/Teste'),

    // },

    {
      name: "articlesByCategory",
      path: "/categories/:id/articles",
      component: () => import("@/components/ArticlesByCategory"),
      meta: { requiresAdmin: true },
    },
    {
      name: "articleById",
      path: "/articles/:id",
      component: () => import("@/components/ArticlesByCategory"),
      meta: { requiresAdmin: true },
    },
    {
      name: "adminAuth",
      path: "/admin/adminAuth",
      component: () => import("@/components/auth/AdminAuth"),
    },

    {
      name: "auth",
      path: "/auth",
      component: () => import("@/components/auth/AdminUsuarios"),
    },
  ],

  // routes: [
  //     {
  //         path: '/',
  //         redirect: '/auth',
  //         component: () => import('@/layouts/Layout'),
  //         children: [
  //             // Components
  //             {
  //                 name: 'Alerts',
  //                 path: 'pages/alerts',
  //                 component: () => import('@/views/pages/Alerts'),
  //             },

  //             {
  //                 name: 'Profile',
  //                 path: 'pages/profile',
  //                 component: () => import('@/views/pages/Profile'),
  //             },

  //             {
  //                 name: 'Icons',
  //                 path: 'pages/icons',
  //                 component: () => import('@/views/pages/Icons'),
  //             },

  //             {
  //                 name: 'TableSimple',
  //                 path: 'pages/tables-simple',
  //                 component: () => import('@/views/pages/TableSimple'),
  //             },

  //             {
  //                 name: 'Dashboard',
  //                 path: 'dashboard/basic-dashboard',
  //                 component: () => import('@/views/dashboard/BasicDashboard'),
  //             },
  //             {
  //                 name: 'home',
  //                 path: '/pages/home',
  //                 component: () => import('@/views/pages/Home'),

  //             },
  //             {
  //                 name: 'adminPages',
  //                 path: '/admin',
  //                 component: () => import('@/views/admin/AdminPages'),
  //                 meta: { requiresAdmin: true}
  //             },
  //             {
  //                 name: 'articlesByCategory',
  //                 path: '/categories/:id/articles',
  //                 component: () => import('@/components/ArticlesByCategory'),
  //             },
  //             {
  //                 name: 'articleById',
  //                 path: '/articles/:id',
  //                 component: () => import('@/components/ArticleById'),
  //             },
  //             {
  //                 name: 'auth',
  //                 path: '/auth',
  //                 component: () => import('@/components/auth/Auth'),

  //             }

  //         ]
  //     },

  // ],
});

// router.beforeRouteLeave ((to, from, next) => {
//     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
//     if (answer) {
//       next()
//     } else {
//       next(false)
//     }
//   })

router.beforeEach((to, from, next) => {
  store.commit("setRotaAtual", to.name);
  next();
});

router.beforeResolve((to, from, next) => {
  const authStatus = store.getters["authStatus"];
  const json = localStorage.getItem(userKey);

  if (to.name != "auth") {
    console.log("to.path" + to.path);
    if (to.path == "/adminAuth" || to.path == "/admin/adminAuth") {
      next();
    } else {
      if (json == null || !authStatus) {
        next({ path: "/auth" });
      } else {
        next();
      }
    }
  } else {
    console.log("meta" + json);
    if (to.matched.some((record) => record.meta.requiresAdmin)) {
      const user = JSON.parse(json);
      user && user.admin ? next() : next({ path: "/" });
    } else if (to.matched.some((record) => record.meta.requiresEmployee)) {
      const user = JSON.parse(json);
      user && user.employee ? next() : next({ path: "/" });
    } else {
      next();
    }
  }
  // if (json == null && to.name != 'auth') {
  //     next({ path: '/auth' })
  // } else
  //     next();

  //         // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //         //     const user = JSON.parse(json)
  //         //     user && user.admin ? next() : next({ path: '/auth'})

  //         // } else {
  //         //      next();
  //         // }
});
// console.log('store teste');
// console.log('store to' + to.name);
// console.log('store to' + from);

// if (to.name != 'auth') {

//     const json = localStorage.getItem(userKey)
//     if (to.matched.some(record => record.meta.requiresAdmin)) {
//         const user = JSON.parse(json)
//         user && user.admin ? next() : next({ path: '/auth'})
//         console.log('store teste 1');

//     } else {
//         console.log('store teste 2');
//         // next( { name: 'auth' } )
//          next();
//     }
// } else {
//     if (to.name != 'auth') {
//         console.log('store teste 3');
//         next( { name: 'auth' } )
//     } else
//     {
//         console.log('store teste 4');
//         next();
//     }
// }

// })

export default router;
// export default new Router({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes: [
//         {
//         path: '/',
//         redirect: 'pages/home',
//         component: () => import('@/layouts/Layout'),
//         children: [
//             // Components
//             {
//                 name: 'home',
//                 path: '/',
//                 redirect: 'pages/home',
//                 component: () => import('@/views/pages/Home'),

//             },
//             {
//                 name: 'admin',
//                 path: '/admin',
//                 redirect: 'admin/AdminPages',
//                 component: () => import('@/views/admin/AdminPages'),

//             }]

//         }

//     ],
// })
