<template>
  <v-app
    id="inspire"
    :class="{ 'hide-menu': !isMenuVisible || !user }"
    v-resize="onResize"
  >
    <Header
      v-if="user && !isMenuVisibleMobile"
      title="Sistema Dashboard WEB"
      v-model="expandOnHover"
      :hideUserDropDown="false"
    />
    <!-- <Header
      v-if="user && (!isMenuVisibleMobile || !$vuetify.breakpoint.smAndDown)"
      title="Sistema Dashboard WEB"
      v-model="expandOnHover"
      :hideUserDropDown="false"
    /> -->

    <Sidebar
      v-if="user && !isMenuVisibleMobile"
      :expand-on-hover.sync="expandOnHover"
    ></Sidebar>
    <!-- <Sidebar v-if="user" :expand-on-hover.sync="expandOnHover"></Sidebar>     -->
    <Loading v-if="validatingToken" />

    <v-main v-else>
      <router-view />
    </v-main>

    <!-- <Footer v-if="user"></Footer> -->
  </v-app>
</template>

<script>
import axios from "axios";
import { baseApiUrl, userKey } from "@/global";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
// import Footer from "./footer/Footer";
import { mapState, mapMutations } from "vuex";
import Loading from "./Loading.vue";

export default {
  name: "Layout",

  components: {
    Header,
    Sidebar,
    // Footer,
    Loading,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    validatingToken: true,
    tamanhoTela: null,
    rota: null,
  }),
  
  computed: {
    ...mapState([
      "Customizer_drawer",
      "isMenuVisible",
      "user",
      "isMenuVisibleMobile",
      "rotaAtual"
    ]),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    async validateToken() {
      this.validatingToken = true;

      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.$store.commit("setUser", null);
     

      if (!userData) {
        console.log("teste auth 1");
        this.validatingToken = false;
        if (this.rotaAtual == "adminAuth") {
          this.$router.push({ name: "adminAuth" });
        } else {
          this.$router.push({ name: "auth" });
        }
        return;
      }

      console.log("Userdata:" + userData);
      const res = await axios.post(`${baseApiUrl}/validateToken`, userData);

      if (res.data) {
        this.$store.commit("setUser", userData);

        if (this.$mq === "xs" || this.$mq === "sm") {
          this.$store.commit("isMenuVisibleMobile", false);
          this.$store.commit("toggleMenu", false);
        }
      } else {
        console.log("teste auth 2");
        localStorage.removeItem(userKey);
        this.$router.push({ name: "auth" });
      }

      this.validatingToken = false;
    },
    onResize() {
      if (this.tamanhoTela != this.$vuetify.breakpoint.name) {
        if (
          (this.tamanhoTela == "xl" ||
            this.tamanhoTela == "lg" ||
            this.tamanhoTela == "md") &&
          (this.$vuetify.breakpoint.name =
            "xs" || this.$vuetify.breakpoint.name == "sm")
        ) {
          this.$store.state.isMenuVisibleMobile = true;
        }
        this.tamanhoTela = this.$vuetify.breakpoint.name;
      }
    },
  },
  created() {
    this.validateToken();
    this.tamanhoTela = this.$vuetify.breakpoint.name;
  },
};
</script>
