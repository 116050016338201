import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        isMenuVisible: false,
        SidebarBg: '',
        user: null,
        rotaAtual: null,
        menuMobile: null,
        isMenuVisibleMobile: true,
        authStatus: false,
        empresa: {
            razaoSocial: '',
            cnpj: ''

        }
    },
    getters: {
        authStatus: state => state.authStatus,
        rotaAtual: state => state.rotaAtual,


    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        toggleMenu(state, isVisible) {
            if (!state.user) {
                state.isMenuVisible = false
                return
            }

            if (isVisible === undefined) {
                state.isMenuVisible = !state.isMenuVisible
            } else {
                state.isMenuVisible = isVisible
            }

        },
        setEmpresa(state, empresa) {
            state.empresa = empresa
        },
        setUser(state, user) {
            state.user = user
            if (user) {
                axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
                state.isMenuVisible = true
            } else {
                delete axios.defaults.headers.common['Authorization']
            }
        },
        setMenuMobile(state, isMenuVisibleMobile) {
            state.isMenuVisibleMobile = isMenuVisibleMobile;


        },
        setAuthStatus(state, authStatus) {
            state.authStatus = authStatus;
        },
        setRotaAtual(state, rotaAtualStatus) {
            state.rotaAtual = rotaAtualStatus;
        }

    },
    actions: {

    }
})