<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    
    clipped
    
    
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <v-list-item class="pa-0">
        
        <v-list-item-content class="pa-0">

          <v-list-item-title v-if="user.nome" class="pa-0" >Usuário : {{user.codigo}} - {{user.nome.toUpperCase()}}</v-list-item-title>
          <!-- <v-list-item-subtitle v-if="user.nome" class="caption">Administrador</v-list-item-subtitle>
          <v-list-item-subtitle v-else class="caption">Usuário</v-list-item-subtitle> -->

        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        color="primary"
        
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
    <!-- <MenuTreeV></MenuTreeV> -->
  </v-navigation-drawer>
  
</template>

<script>
import { mapState } from "vuex";

// import MenuTreeV from "@/layouts/MenuTreeV.vue";


export default {
  name: "Sidebar",
  // components : {  MenuTreeV },
  props: {
    expandOnHover: {
      type: Boolean,
      default: true
    }
  },
  
  data: () => ({
    items: [
      // {
      //   title: "Dashboard",
      //   icon: "mdi-view-dashboard",
      //   to: "/dashboard/basic-dashboard"
      // },

      // {
      //   title: "Profile",
      //   icon: "mdi-account-circle",
      //   to: "/pages/profile"
      // },

      // {
      //   title: "Alerts",
      //   icon: "mdi-alert",
      //   to: "/pages/alerts"
      // },

      // {
      //   title: "Icons",
      //   icon: "mdi-emoticon",
      //   to: "/pages/icons"
      // },

      // {
      //   title: "Basic Table",
      //   icon: "mdi-table-column-width",
      //   to: "/pages/tables-simple"
      // },
      {
        title: "Dashboard",
        icon: "mdi-bandage",
        to: "/"
      },
      {
        title: "Log de Acesso",
        icon: "mdi-view-list-outline",
        to: "/adminAccessLog"
      }


    ]
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg", "user"]),
    
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
      
    },
    "$vuetify.breakpoint.smAndUp"(val) {
      this.$emit("update:expandOnHover", !val);
      
    }
  },

  methods: {}
};
</script>
<style lang="scss">
#main-sidebar{
  box-shadow:1px 0 20px rgba(0,0,0,.08);
  -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
  .v-navigation-drawer__border{
    display: none;
  }
  .v-list{
    padding: 8px 15px;
  }
  .v-list-item{
      &__icon--text,
      &__icon:first-child{
        justify-content: center;
        text-align: center;
        width: 20px;
        
      }
      
  }    
}
</style>