<template>
  <v-card class="mb-2 pa-0">
    <v-toolbar flat class="pa-0">
      <v-layout row wrap align-start>
        <v-icon
          id="icone"
          v-if="icon"
          :class="icon"
          class=""
          style="margin-top: 2px"
          >{{ icon }}</v-icon
        >
        <v-toolbar-title class="pa-0 ma-0">
          {{ main }}
          <v-card-subtitle class="pa-0">
            {{ sub }}
          </v-card-subtitle>
        </v-toolbar-title>
      </v-layout>
      <v-spacer></v-spacer>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    main: {
      type: String,
      default: "",
    },
    sub: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),

  methods: {},
};
</script>
<style>
</style>